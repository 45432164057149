
//Widgets types

export const BUTTON = "BUTTON";
export const LABEL = "LABEL";
export const EMAIL_INPUT = "EMAIL_INPUT";
export const INPUT = "INPUT";
export const TITLELABLE = "TITLELABLE";

export const PASSWORD_INPUT = 'PASSWORD_INPUT';
export const CHECKBOX = 'CHECKBOX';

export const CAPTCHA = 'CAPTCHA';
export const AXIOSLINK = 'https://isrid.testsitemegarcus.com/'
export const BASEURL = 'https://www.testsitemegarcus.com/'
export const IMAGELINK = 'https://isrid.testsitemegarcus.com'


